exports.components = {
  "component---src-pages-company-en-js": () => import("./../../../src/pages/company.en.js" /* webpackChunkName: "component---src-pages-company-en-js" */),
  "component---src-pages-company-se-js": () => import("./../../../src/pages/company.se.js" /* webpackChunkName: "component---src-pages-company-se-js" */),
  "component---src-pages-contacts-en-js": () => import("./../../../src/pages/contacts.en.js" /* webpackChunkName: "component---src-pages-contacts-en-js" */),
  "component---src-pages-contacts-se-js": () => import("./../../../src/pages/contacts.se.js" /* webpackChunkName: "component---src-pages-contacts-se-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-se-js": () => import("./../../../src/pages/index.se.js" /* webpackChunkName: "component---src-pages-index-se-js" */),
  "component---src-pages-requirements-en-js": () => import("./../../../src/pages/requirements.en.js" /* webpackChunkName: "component---src-pages-requirements-en-js" */),
  "component---src-pages-requirements-se-js": () => import("./../../../src/pages/requirements.se.js" /* webpackChunkName: "component---src-pages-requirements-se-js" */)
}

